<template>
  <div class="content">
    <!-- Register board -->
    <b-sidebar id="sidebar-add-pipeline" ref="createPipeline" :title="$t('catalog.create')" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Criar Pipeline</p>
      </div>
      <div class="col title-column" v-if="fetched">
        <div class="title-task">
          <input v-model="title" v-on:keyup.13="registerBoard" type="text" class="form-control w-100" :placeholder="$tc('app.name', 1)">
        </div>
      </div>
      <div class="col description-column" v-if="fetched">
        <div class="title-task">
          <textarea v-model="description" type="text" class="form-control w-100" :placeholder="$t('app.description')" rows="7" />
        </div>
      </div>
      <div class="col btn-wrapper">
        <button class="btn btn-outline-primary mt-3" v-b-toggle.sidebar-add-pipeline>{{$t('app.cancel')}}</button>
        <button class="btn btn-primary mt-3 ml-auto" @click="registerBoard" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}" :disabled="isSending">{{$t('app.save')}}</button>
      </div>
    </b-sidebar>
    <!--/Register board -->
    <div class="page-content container-fluid settings">
      <div class="row">
        <div class="col-12 col-sm">
          <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Pipeline" />
          <!-- Search column -->
          <div class="col-8 mx-auto mb-5">
            <div class="form-wrapper">
              <form @submit.prevent="fetch" class="form-group col px-0 mb-0">
                <div class="input-group">
                  <input
                    :placeholder="$t('generic-str.search')"
                    type="text"
                    class="form-control"
                    :v-model="searchQuery"
                    ref="searchInput"
                  />
                  <div class="input-group-prepend">
                    <button @click="fetch(1)" type="button" class="btn icon_btn m-0">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!--/Search column -->
          <!-- Workspace content -->
          <div class="row mb-4 workspace-row">
            <div class="col-12 d-flex">
              <span class="material-symbols-outlined mr-2"> table_chart </span><span class="h5">{{$t('workflow.workspace.title')}}</span>
              <ul class="list-unstyled options-cat">
                <!-- <li class="filter-opt" v-b-toggle.dd-filter></li> -->
                <b-dd id="dd-filter" variant="link p-0 filter-opt" toggle-class="text-decoration-none" right no-caret>
                  <template #button-content>
                    <span class="mr-1">{{$t('app.order-by')}} ({{$t(`app.${selectedOrderWorkspace}-order`)}})</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" role="button" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="sort-descending"><path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" /></svg>
                  </template>
                  <b-dd-item @click="workspaceOrder('latest')">{{$t('app.latest-order')}}</b-dd-item>
                  <b-dd-item @click="workspaceOrder('title')">{{$t('app.title-order')}}</b-dd-item>
                  <!-- <b-dd-item @click="workspaceOrder('latest')">Adicionado por último</b-dd-item> -->
                  <b-dd-item @click="workspaceOrder('active')">{{$t('app.active-order')}}</b-dd-item>
                  <b-dd-item @click="workspaceOrder('inactive')">{{$t('app.inactive-order')}}</b-dd-item>
                  <!-- <b-dd-item>Pausado</b-dd-item> -->
                  <b-dd-item @click="workspaceOrder('archived')">{{$t('app.archived-order')}}</b-dd-item>
                  <b-dd-item @click="workspaceOrder('done')">{{$t('app.done-order')}}</b-dd-item>
                </b-dd>
                <!-- <li><span class="material-symbols-outlined" role="button"> grid_view </span></li>
                <li><span class="material-symbols-outlined" role="button"> list </span></li> -->
              </ul>
            </div>
            <div class="col-12 col-sm-4 px-0 col-workspace">
              <div class="card card-workspace">
                <div class="card-body d-flex">
                  <img v-b-toggle.sidebar-add-pipeline role="button" class="img-add" src="@/assets/add-dashed-circle-no-loop.gif" alt="Add" width="167">
                </div>
              </div>
            </div>
            <template v-if="fetched">
              <div class="col-12 col-sm-4 px-0 col-workspace" v-for="board in boards" :key="board.id">
                <router-link :to="`/pipelines/`+ board.id" class="card card-workspace">
                  <div class="card-header">
                    <!-- <span class="badge badge-optional">● {{statusType[Math.round(Math.random()*3)]}}</span> -->
                    <span v-if="board.archived === true" class="badge badge-readed">● {{$t('generic-str.status.lbl-filed')}}</span>
                    <span v-else class="badge badge-optional">● {{$t(`app.` + board.status)}}</span>
                    <b-dropdown right variant="link p-0" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <span class="material-symbols-outlined config_dot">more_horiz</span><span class="sr-only">Search</span>
                      </template>
                      <!-- <b-dd-item href="#"><span class="material-symbols-outlined"> edit </span>Renomear</b-dd-item> -->
                      <b-dd-item href="#" @click="duplicate(board.id, board.title)"><span class="material-symbols-outlined"> content_copy </span>{{$t('generic-str.duplicate')}}</b-dd-item>
                      <!-- <b-dd-item href="#"><span class="material-symbols-outlined"> drive_file_move </span>Mover</b-dd-item> -->
                      <b-dd-item href="#" @click="setArchived(board.id, board.title)"><span class="material-symbols-outlined"> archive </span>{{$t('workflow.workspace.board.archive')}}</b-dd-item>
                      <b-dd-item href="#" @click="deleteBoard(board.id)"><span class="material-symbols-outlined text-danger"> delete_forever </span>{{$t('app.delete')}}</b-dd-item>
                    </b-dropdown>
                  </div>
                  <div class="card-body">
                    <h4 class="card-text">{{board.title}}</h4>
                    <time>
                      <!--
                        <template v-if="board.due_date">{{board.created_at | displayDate}} - {{board.due_date | displayDate}}</template>
                        <template v-else>&nbsp;</template>
                      -->
                      <template>{{board.created_at | displayDate}}</template>
                    </time>
                    <p class="pt-2 description">{{board.description}}</p>
                    <!-- <b-progress id="" class="progress-yup" :value="randomNumber = Math.round(Math.random()*100)" :max="100" variant="yup" /> -->
                  </div>
                  <div class="card-footer profile-wrapper">
                    <!-- <img src="@/assets/w-1.png" alt="Woman 1" srcset="">
                    <img src="@/assets/w-2.png" alt="Woman 1" srcset=""> -->
                    <div class="profile" v-for="department in board.departments" :key="department.id" :style="{ backgroundColor: $strToColor(department.name) }">
                      {{department.name | initials}}
                    </div>
                    <div class="profile" v-for="user in board.users" :key="user.id" :style="{ backgroundColor: $strToColor(user.name) }">
                      {{user.name | initials}}
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
            <div class="loading min-h-300 scrollable-loading" v-else>
              <LoadingAnim />
            </div>
            <!-- <pre>{{pages}}</pre> -->
            <Pagination :lastPage="pages" @change="fetch" v-if="fetched" />
          </div>
          <!--/Workspace content -->
        </div>
        <!-- Calendario -->
        <div class="col-12 col-sm-4 col-calendar d-none">
          <p class="h4">{{$t('app.calendar')}}</p>
          <div class="row">
            <div class="col-12 date-wrapper">
              <span class="date">25 Abril, Ter</span>
              <div class="control-calendar">
                <span class="material-symbols-outlined" role="button"> navigate_before </span>
                <span class="material-symbols-outlined" role="button"> navigate_next </span>
                <span>Hoje</span>
              </div>
            </div>
            <!-- Active state -->
            <div class="col-12 d-flex hour-wrapper active">
              <p class="hour">08:00</p>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple">08:35</div>
                <hr class="active">
              </div>
            </div>
            <!--/Active state -->
            <!-- Normal state (blank) -->
            <div class="col-12 d-flex hour-wrapper blank">
              <p class="hour">09:00</p>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple blank">00:00</div><!-- blank -->
                <hr class="">
              </div>
            </div>
            <!--/Normal state (blank) -->
            <!-- Normal state -->
            <div class="col-12 d-flex hour-wrapper scheduled">
              <p class="hour">10:00</p>
              <div class="card">
                <div class="card-body">
                  <span class="card-text">Lead Carlos</span>
                  <div class="time">
                    <span class="material-symbols-outlined" role="button"> schedule </span>
                    <span>00:00pm</span>
                  </div>
                </div>
              </div>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple blank">00:00</div><!-- blank -->
                <hr class="">
              </div>
            </div>
            <!--/Normal state -->
            <!-- Normal state (blank) -->
            <div class="col-12 d-flex hour-wrapper scheduled blank">
              <p class="hour">11:00</p>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple blank">00:00</div><!-- blank -->
                <hr class="">
              </div>
            </div>
            <!--/Normal state (blank) -->
            <!-- Urgent state -->
            <div class="col-12 d-flex hour-wrapper scheduled">
              <p class="hour">10:00</p>
              <div class="card urgent">
                <div class="card-body">
                  <span class="card-text">Lead Carlos</span>
                  <div class="time">
                    <span class="material-symbols-outlined" role="button"> schedule </span>
                    <span>00:00pm</span>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="badge badge-urgent">● {{$t('workflow.workspace.status.urgent')}}</div>
                </div>
              </div>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple blank">00:00</div><!-- blank -->
                <hr class="">
              </div>
            </div>
            <!--/Urgent state -->
            <!-- Normal state (blank) -->
            <div class="col-12 d-flex hour-wrapper scheduled blank">
              <p class="hour">13:00</p>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple blank">00:00</div><!-- blank -->
                <hr class="">
              </div>
            </div>
            <!--/Normal state (blank) -->
            <!-- Multi state -->
            <div class="col-12 d-flex hour-wrapper scheduled">
              <p class="hour">10:00</p>
              <div class="card high">
                <div class="card-body">
                  <span class="card-text">Lead Carlos</span>
                  <div class="time">
                    <span class="material-symbols-outlined" role="button"> schedule </span>
                    <span>00:00pm</span>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="badge badge-high">● {{$t('workflow.workspace.status.high')}}</div>
                </div>
              </div>
              <div class="card normal">
                <div class="card-body">
                  <span class="card-text">Lead Carlos</span>
                  <div class="time">
                    <span class="material-symbols-outlined" role="button"> schedule </span>
                    <span>00:00pm</span>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="badge badge-normal">● {{$t('workflow.workspace.status.normal')}}</div>
                </div>
              </div>
              <div class="appointment-wrapper d-flex align-items-center w-100">
                <div class="badge badge-purple blank">00:00</div><!-- blank -->
                <hr class="">
              </div>
            </div>
            <!--/Multi state -->
          </div>
        </div>
        <!--/Calendario -->
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Pagination from '@/components/rebranding/Pagination.vue';
// import HiddenInput from '@/components/HiddenInput.vue';
// import WhatsService from '@/services/whatsapp.service';
// import Darkmode from '@/components/rebranding/SetTheme.vue';
import Swal from 'sweetalert2';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'Workspace',
  components: {
    PageHeader,
    Pagination,
    // HiddenInput,
    // Darkmode,
    LoadingAnim,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/pipelines',
      },
      fetched: false,
      searchQuery: null,
      url: null,
      title: '',
      description: '',
      isSending: false,
      boards: [],
      selectedOrderWorkspace: '',
      pages: 1,
      // Test purpose
      // randomNumber: Number,
      statusType: ['Ativo', 'Finalizado', 'Pausado', 'Arquivado'],
      // statusSort: [],
    };
  },
  created() {
    if (!localStorage.getItem('workSpaceOrder')) {
      console.log('Has order previously selected');
      this.selectedOrderWorkspace = 'latest';
    } else {
      console.log('Has not: ', localStorage.getItem('workSpaceOrder'));
      this.selectedOrderWorkspace = localStorage.getItem('workSpaceOrder');
    }
    setTimeout(() => {
      this.fetch();
    }, 500);
  },
  methods: {
    search(page) {
      console.info('Search function');
      this.fetched = false;
      const query = this.$refs.searchInput.value;
      webApi.get(`/pipelines?query=${query}&?sort=${this.selectedOrderWorkspace}&page=${page}`).then(
        (response) => {
          console.log('Board response:\n ', response);
          this.fetched = true;
          this.boards = response.data.data;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(pages) {
      this.fetched = false;
      const query = this.$refs.searchInput.value;
      webApi.get((query !== '' ? `/pipelines?query=${query}&sort=${this.selectedOrderWorkspace}&page=${this.pages}` : `/pipelines?sort=${this.selectedOrderWorkspace}&page=${this.pages}`)).then(
        (response) => {
          console.log('Board response:\n ', response);
          this.fetched = true;
          this.boards = response.data.data;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    workspaceOrder(e) {
      this.selectedOrderWorkspace = e;
      localStorage.setItem('workSpaceOrder', e);
      this.fetch(1);
    },
    /* sortArray() {
      this.statusSort = this.statusType.sort((a, b) => 0.5 - Math.random());
    }, */
    duplicate(id, title) {
      Swal.fire({
        title: `Duplicar ${title}?`,
        text: 'Confirmar duplicatar todo o quadro, incluindo colunas e cards?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          webApi.post(`/pipelines/${id}/duplicate`).then(
            (response) => {
              console.log('Task: response: ', response);
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Duplicado com sucesso',
                type: 'success',
              });
            },
            (error) => {
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
    setArchived(id, title) {
      Swal.fire({
        title: `${this.$t('workflow.workspace.board.archive')} ${title}?`,
        text:
          this.$t('workflow.workspace.board.archive-desc'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('workflow.workspace.board.archive'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('My ID: ', id);
          webApi.post(`/pipelines/${id}/archive`).then(
            (response) => {
              console.log('Task: response: ', response);
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('workflow.workspace.card.edit.success'),
                type: 'success',
              });
            },
            (error) => {
              console.error({ error });
              this.content = error;
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
    showModalRegister() {
      this.$refs.createPipeline.show();
    },
    hideModalRegister() {
      this.title = '';
      this.description = '';
      this.$refs.createBoard.hide();
    },
    toggleSidebar(id) {
      this.title = '';
      this.description = '';
      this.$root.$emit('bv::toggle::collapse', id);
    },
    registerBoard() {
      this.isSending = true;
      webApi.post('/pipelines/', {
        title: this.title,
        description: this.description,
      }).then((responses) => {
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: this.$t('workflow.workspace.board.create.success'),
          type: 'success',
        });
        this.fetch();
        this.toggleSidebar('sidebar-add-pipeline');
        this.isSending = false;
      }).catch((error) => {
        console.error({ error });
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
          content: error.message,
          type: 'danger',
        });
        this.isSending = false;
      });
    },
    deleteBoard(id) {
      Swal.fire({
        title: `${this.$t('generic-str.remove')} board?`,
        text: this.$t('workflow.workspace.board.remove'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        webApi.delete(`/pipelines/${id}`).then(
          (response) => {
            this.$toast.show({
              title: 'Sucesso',
              content: 'Board removido',
              type: 'success',
            });
            this.fetch();
          }, (error) => {
            console.error({ error });
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        );
      });
    },
    errorMessage(relativeTo, message) {
      // Create an "div" node:
      const node = document.createElement('div');
      const errorElm = document.getElementById('warning-text');
      node.setAttribute('id', 'warning-text');

      // Create a text node:
      const textnode = document.createTextNode(message);

      // Append the text node to the "div" node:
      node.appendChild(textnode);

      if (!errorElm) {
        // First time create warning div and append
        this.warningAnim(node); // Animate
        relativeTo.appendChild(node);
      } else {
        // If exist, just change text, then animate
        errorElm.innerHTML = message;
        this.warningAnim(errorElm); // Animate
      }
    },
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
    displayDate(date) {
      const today = new Date();
      const current = new Date(date);

      if (today.toDateString() === current.toDateString()) {
        return 'Hoje';
      }

      const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
      const y = current.getFullYear();
      const m = current.getMonth();
      const d = `${current.getDate()}`.padStart(2, 0);

      return `${d} ${months[m]}, ${y}`;
    },
  },
};
</script>
<style scoped lang="scss">
.page-header {
  padding-bottom: 2rem;
}

/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
/*/Search Form style */
.container-add, .container-catalogs {
  max-width: 1740px;
}

/* List */
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding: 8px 20px;
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover, &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6B23DC3D;
  border-radius: 75px;
}
.content-row {
  display: flex;
  background-color: var(--background-2);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    align-items: center;
    min-height: 72px;
  }
  @media (min-width: 768px) {
    div:last-child {
      justify-content: center;
    }
  }
}
.options-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.75rem;
  margin-bottom: unset;
  li, .dropdown-toggle span {
    color: var(--gray-font-color-5);
  }
  .dropdown-toggle:hover, .dropdown-toggle:focus {
    color: var(--gray-font-color);
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #FFF;
  transform: scale(1.6);
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: unset;
  li {
    color: var(--gray-font-color-5);
  }
}
::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
.prod-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-position: center;
}
.info-wrapper {
  display: flex;
  flex-direction: column;

  a:not(.material-symbols-outlined), span:not(.material-symbols-outlined) {
    font-weight: 400;
    padding: 8px 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: unset !important;
  }
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: unset !important;
    color: var(--gray-font-color-5);
  }
}
.value-col {
  color: var(--gray-font-color-5);
}
.var-col {
  .var {
    padding: 4px 8px;
    background-color: #807C8D14;
    color: var(--gray-font-color-5);
    border-radius: 8px;
    margin-right: 10px;
  }
}

/* Workflow */
.sort-descending {
  width: auto;
  height: 29px;
}

.filter-opt {
  display: flex;
  * {
    color: var(--gray-font-color-6);
    font-weight: 400;
  }
}

/* Card workspace */
.card-workspace {
  & > .card-header {
    // padding-bottom: 5px;

    &::v-deep .dropdown-toggle {
      height: 24px !important;
    }
  }

  & > .card-body {
    padding-top: 5px;

    time {
      color: var(--gray-font-color);
      font-size: .8rem;
    }

    .description {
      font-weight: normal;
    }
  }

  /* width: 40%; */
  margin-bottom: 0;
  /* margin-right: 50px; */
  /* flex: 0 1 45%; */
  height: 100%;

  .card-header {
    display: flex;

    .dropdown {
      margin-left: auto;
    }

    .dropdown-toggle span {
      color: var(--gray-font-color-5);
    }

    .dropdown-toggle:hover, .dropdown-toggle:focus {
      color: var(--gray-font-color);
    }

    span {
      height: fit-content;
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;

    p {
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    .progress-yup {
      margin-top: 1.5rem;
    }
  }
  .card-footer {
    display: flex;
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden;
      box-shadow: 0 0 0 4px #fff;
      pointer-events: none;
      color: #fff;
      svg {
        width: 20px;
        height: 20px;
      }

      &.faded-icon {
        background-color: var(--c-grey-100);
        color: var(--c-grey-400);
      }

      &.filled-icon {
        background-color: var(--c-blue-500);
        color: #fff;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  small {
    color: var(--gray-font-color);
  }
}
.workspace-row {
  .col-workspace {
    margin-bottom: 50px;
  }
  .col-workspace:nth-of-type(2) .card.card-workspace {
    box-shadow: 0px 3px 26px 5px rgba(107, 35, 220, 0.16) !important;
  }
  @media (min-width: 768px) {
    padding: 0 8rem 0 1rem;
    // .col-workspace:nth-of-type(2n) {
    .col-workspace:nth-of-type(1n) {
      // padding-left: 5rem !important;
      .card-workspace {
        margin-right: .5rem;
      }
    }
    // .col-workspace:nth-of-type(2n+1) {
    .col-workspace:nth-of-type(1n+1) {
      // padding-right: 5rem !important;
      .card-workspace {
        margin-left: .5rem;
      }
    }
  }
  @media screen and (min-width: 1360px) and (max-width: 1660px) {
    .col-workspace:nth-of-type(2n) {
      .card-workspace {
        margin-right: .5rem;
      }
    }
    .col-workspace:nth-of-type(2n+1) {
      .card-workspace {
        margin-left: .5rem;
      }
    }
    padding: 0 2rem 0 1rem;
  }
}
.img-add {
  width: 100%;
  margin: auto;
  max-width: 250px;
  max-height: 250px;
}
/* @media (min-width: 768px) {
  .card-workspace {
    min-width: 480px;
  }
} */

.progress-yup {
  width: 100%;
  /* &:before {
    content: '';
    background-image: url(/assets/import_csv.svg);
    width: 31px;
    height: 36px;
  } */
}
/*/Card workspace */
.col-calendar {
  background-color: #F7F7F7;
  padding-top: 2rem;

  .hour-wrapper {
    .card:nth-of-type(2n+1):not(.card:first-of-type) {
      margin-left: 50px !important;
    }
  }

  > p {
    margin-bottom: 3rem;
  }
.date-wrapper {
  display: flex;
  .date {
    font-weight: bold;
    color: var(--foreground);
  }
  .control-calendar {
    display: flex;
    gap: 6px;
    margin-left: auto;
    }
    span {
      color: var(--gray-font-color-4);
      font-weight: 400;
    }
  }
  .hour-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 3.5rem 0 3.5rem;
    &.blank p {
      width: 100%;
    }
    /* &.scheduled p {
      height: 100%;
    } */
    hr {
      flex: 1 0 auto;
      // border-top-style: dashed;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E3E6FF' stroke-width='4' stroke-dasharray='15%2c 30' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
      border: unset !important;
      height: 6%;
    }
    &.blank .badge, .badge.blank {
      opacity: 0;
      cursor: default;
    }

    &.scheduled .card {
      flex: 1 0 auto;
      margin-left: 15px;
      border-left: 5px solid #c2c2c2;
      .card-body, .card-footer {
        padding: 3px 5px;
      }
      .card-body {
        display: flex;
        color: var(--gray-font-color-4);
        font-weight: 400;

        .time {
          display: flex;
          align-items: center;
          gap: 3px;
          margin-left: auto;
          * {
            font-size: .875rem;
          }
        }
      }
      .card-footer {
        font-size: .85rem;
        padding-bottom: 5px;
      }

      // Status
      &.normal,&.medium {
        border-left: 5px solid #5A99EC;

        .badge.badge-normal, .badge.badge-medium {
          border-radius: 8px;
        }
      }
      &.high {
        border-left: 5px solid #FFB200;

        .badge.badge-high {
          border-radius: 8px;
        }
      }
      &.urgent {
        border-left: 5px solid #FF0000;

        .badge.badge-high {
          border-radius: 8px;
        }
      }
    }
  }
  .hour-wrapper.active {
    hr {
      border-top: 2px solid var(--clr-yup-purple) !important;
      background-image: unset !important;
    }
  }
}
::v-deep {
  #warning-text {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-top: 1rem;
    background-color: var(--urgent-bg);
    color: var(--urgent-fg);
    padding: 5px 10px;
    border-radius: 8px;
    transition: .5s;
    font-weight: 400;
    height: 100%;

    &::before {
      content: '\e000';
      font-family: 'Material Symbols Outlined';
      font-size: 1.25rem;
    }
  }

  #warning-text.error-anim {
    animation: horizontal-shaking 0.18s infinite;
  }

  @keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }
}
/* Sidebar add prod */
::v-deep .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    margin-bottom: 2rem;
    .sidebar-title {
      color: var(--foreground);
      font-size: 1.25rem;
    }
    .sidebar-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    p {
      display: block;
      font-size: 1rem;
      margin-bottom: 1px;
    }
    p, span.material-symbols-outlined {
      color: var(--gray-font-color-2);
    }
  }
  .title-column {
    margin-bottom: 2rem;
    .title-task {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      /* * {
        font-size: 1.625rem;
      } */
      .not-editing {
        margin-right: 1rem;
      }
    }
  }
  .responsable-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    margin-bottom: 2.5rem;
    p {
      font-size: 1rem;
      margin-right: .5rem;
      margin-bottom: 0;
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:last-of-type {
        margin-right: .5rem;
      }
    }
  }
  .profile-wrapper {
    display: flex;
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden;
      box-shadow: 0 0 0 4px #fff;
      pointer-events: none;
      color: #fff;
      svg {
        width: 20px;
        height: 20px;
      }

      &.faded-icon {
        background-color: var(--c-grey-100);
        color: var(--c-grey-400);
      }

      &.filled-icon {
        background-color: var(--c-blue-500);
        color: #fff;
      }
    }
  }
  .assign-user-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label-sidebar-item {
      width: fit-content !important;
      margin-right: .5rem;
    }
    svg {
      color: var(--gray-font-color);
      cursor: pointer;
      margin-top: 0 !important;
    }
  }
  .description-column {
    > p {
      font-size: 1rem;
      margin-bottom: .5rem;
    }
    & p:last-of-type {
      font-size: .875rem;
      color: var(--gray-font-color);
    }
    /* textarea {
      margin-bottom: 1px;
    } */
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;

    .sidebar-item {
      margin-bottom: 3rem;
      .label-sidebar-item {
        display: block;
        width: 100%;
        font-weight: 400;
        color: var(--foreground);
        // margin-bottom: 1.5rem;
      }
      img.profile-img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        margin-right: .5rem;
      }
      .label-sidebar-item ~ * {
        margin-top: 1rem;
      }
    }
  }
  header {
    >strong {
      order: -1;
    }
  }
  .title-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend:not(:first-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .create-custom-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
  }
}
</style>
